import React from 'react'
import { register } from 'swiper/element/bundle';
import PisoLaminado from '../../assets/laminado-carrousel.jpg'
import Persianas from '../../assets/persianas2.jpg'
import PanelExterior from '../../assets/panelExterior.webp'
import PanelInterior from '../../assets/paneles-interior.jpeg'
import PisoEstructurado from '../../assets/pisoEstrcutrado.jpg'
import PisoAlfombra from '../../assets/tipoAlfombra.jpg'
import PisoSpc from '../../assets/pisospc.jpg'
import { Link } from 'react-router-dom'
// register Swiper custom elements
register();


const PagesCarrousel = () => {
  return (
    <div className='bg-white md:py-5 pt-3  md:px-10 px-1'>
      <div className='flex w-full overflow-x-scroll gap-3 md:gap-0 md:justify-center '>


        <Link to="/laminados" className='flex-1 w-full transform hover:scale-105 ease-in duration-100'>
          <div className='h-full  w-[200px] flex flex-col cursor-pointer justify-center items-center text-center    text-black'>
            <div className='md:w-[70%] w-full  h-full'>
              <div className='border h-32 border-gray-200' >
                <img className='h-full w-full object-cover' src={PisoLaminado} alt="Pisos Laminados"></img>

              </div>
              <h1 className='py-3'> Piso Laminado</h1>
            </div>

          </div>
        </Link>


        <Link to="/estructurados" className='flex-1 transform hover:scale-105 ease-in duration-100'>
          <div className=' max-h-50  w-[200px] flex flex-col cursor-pointer justify-center items-center text-center    text-black'>
            <div className='md:w-[70%] w-full  h-50'>
              <div className='border h-32  bg-cover bg-bottom  border-gray-200' >
                <img className='h-full w-full object-cover' src={PisoEstructurado} alt="Pisos Estructurados"></img>


              </div>
              <h1 className='py-3'> Piso Estructurado</h1>
            </div>

          </div>
        </Link>


        <Link to="/spc" className='flex-1 transform hover:scale-105 ease-in duration-100'>
          <div className=' h-50  w-[200px] flex flex-col cursor-pointer justify-center items-center text-center    text-black'>
            <div className='md:w-[70%] w-full'>
              <div className='border h-32  bg-cover bg-bottom  border-gray-200' >
                <img className='h-full w-full object-cover' src={PisoSpc} alt="Pisos Spc"></img>

              </div>
              <h1 className='py-3'> Piso SPC</h1>
            </div>

          </div>
        </Link>




        <Link to="/panelesInterior" className='flex-1 transform hover:scale-105 ease-in duration-100'>
          <div className=' h-50  w-[200px] flex flex-col cursor-pointer justify-center items-center text-center    text-black'>
            <div className='md:w-[70%] w-full'>
              <div className='border h-32  bg-cover bg-center  border-gray-200' >
                <img className='h-full w-full object-cover' src={PanelInterior} alt="Paneles Pvc Interior"></img>

              </div>
              <h1 className='py-3'> Paneles Interior</h1>
            </div>

          </div>
        </Link>


        <Link to="/persianas" className='flex-1 transform hover:scale-105 ease-in duration-100'>
          <div className=' h-50  w-[200px] flex flex-col cursor-pointer justify-center items-center text-center    text-black'>
            <div className='md:w-[70%] w-full'>
              <div className='border h-32  bg-cover bg-center  border-gray-200' >
                <img className='h-full w-full object-cover' src={Persianas} alt="Persianas"></img>

              </div>
              <h1 className='py-3'> Persianas</h1>
            </div>

          </div>
        </Link>


        <Link to="/pisoAlfombra" className='flex-1 transform hover:scale-105 ease-in duration-100'>
          <div className=' h-50  w-[200px] flex flex-col cursor-pointer justify-center items-center text-center    text-black'>
            <div className='md:w-[70%] w-full'>
              <div className='border h-32  bg-cover bg-bottom  border-gray-200'>
                <img className='h-full w-full ' src={PisoAlfombra} alt="Piso tipo Alfombra"></img>

              </div>
              <h1 className='py-3'> Piso Alfombra</h1>
            </div>

          </div>
        </Link>
      </div>



    </div>
  )
}

export default PagesCarrousel