import React from 'react'


import { useState } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contacto = () => {
    const [values, setValues] = useState({});

    const sendEmail = async (e) => {
        e.preventDefault();





        const response = await axios.post("https://pisos-parador-backend.onrender.com/api/sendemail", values);

        if (response.status === 200) {
            document.getElementById("nombre").value = "";
            document.getElementById("apellido").value = "";
            document.getElementById("email").value = "";
            document.getElementById("telefono").value = "";
            document.getElementById("mensaje").value = "";
            toast.success('Email fue enviado!', {
                position: toast.POSITION.TOP_CENTER
            });

        }
        else {
            toast.error('error enviando el email !', {
                position: toast.POSITION.TOP_CENTER
            });
        }






    };

    const handleInputChange = (event) => {
        setValues((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));

    }



    return (
        <div className='min-h-screen md:py-0  bg-black  '>
            <h1 className='text-white text-4xl text-center '>¿En qué podemos ayudarte?</h1>
            <h1 className='text-white text-4xl text-center '> Envíanos un mensaje.</h1>

            <div className='w-full h-full md:py-10 bg-black  flex justify-center '>
                <form className=' w-full w-full px-5 py-3  rounded-xl ' onSubmit={sendEmail}>
                    <div className='h-auto w-full gap-5 flex md:flex-row flex-col'>
                        <div className='flex flex-col w-full'>
                            <label className='text-white'>Nombre</label>
                            <input required onChange={handleInputChange} id="nombre" name='nombre' type="text" className='rounded py-3  border border-gray-200' style={{ boxShadow: "0px 2px 8px rgba(244,38,62,0.04), 0px 1px 3px rgba(31,37,89,0.07)" }}></input>
                        </div>
                        <div className='flex flex-col w-full'>
                            <label className='text-white'>Apellido</label>
                            <input required onChange={handleInputChange} id="apellido" name='apellido' type="text" className='rounded py-3  border border-gray-200' style={{ boxShadow: "0px 2px 8px rgba(244,38,62,0.04), 0px 1px 3px rgba(31,37,89,0.07)" }}></input>
                        </div>

                    </div>
                    <div className='h-auto w-full gap-5 flex md:flex-row flex-col mt-3'>
                        <div className='flex flex-col w-full'>
                            <label className='text-white'>Email</label>
                            <input required onChange={handleInputChange} id="email" name='email' type="text" className='rounded py-3  border border-gray-200' style={{ boxShadow: "0px 2px 8px rgba(244,38,62,0.04), 0px 1px 3px rgba(31,37,89,0.07)" }}></input>
                        </div>
                        <div className='flex flex-col w-full'>
                            <label className='text-white'>Telefono</label>
                            <input required onChange={handleInputChange} id="telefono" name='telefono' type="number" className='rounded py-3  border border-gray-200' style={{ boxShadow: "0px 2px 8px rgba(244,38,62,0.04), 0px 1px 3px rgba(31,37,89,0.07)" }}></input>
                        </div>

                    </div>
                    <div className='h-auto w-full mt-5 '>
                        <div className='flex flex-col w-full'>
                            <label className='text-white'>Mensaje</label>
                            <textarea required onChange={handleInputChange} id="mensaje" name='mensaje' className='min-h-[300px] border border-gray-200 rounded' style={{ boxShadow: "0px 2px 8px rgba(244,38,62,0.04), 0px 1px 3px rgba(31,37,89,0.07)" }}></textarea>
                        </div>

                    </div>
                    <div className='h-auto w-full mt-5 '>
                        <div className='flex w-full justify-start'>
                            <button type='submit' className='bg-[#a17e67] px-10 py-3 rounded transition hover:scale-110 text-white'>Enviar Mensaje</button>

                        </div>

                    </div>
                    <ToastContainer />

                </form>
            </div>

        </div>



    )
}

export default Contacto