

import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './pages/Home.jsx'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Estructurados from './pages/Estructurados';
import Laminados from './pages/Laminados';
import Spc from './pages/Spc';
import Contacto from './pages/Contacto';
import Persianas from './pages/Persianas';
import PisoAlfombra from './pages/PisoAlfombra';
import PanelesExterior from './pages/PanelesExterior';
import PanelesInterior from './pages/PanelesInterior';
import Login from './pages/Login';
import QuienesSomos from './pages/QuienesSomos';
import { useState } from "react"
import ProductoImagenes from './pages/ProductoImagenes';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import LogoWa from './assets/pisoLaminado.jpg'
import MaintenanceImage from './assets/web-maintenance.png'
import DashBoard from './components/Login/DashBoard';
import MaintenancePage from './components/MaintenancePage';

function App() {
  const [showNav, setShowNav] = useState(true);
  const [maintenance, setMaintenance] = useState(true);

  return (
    <>
      {!maintenance ? <BrowserRouter>
        <div className="App">
          {showNav &&
            <Navbar />

          }

          <Routes>
            <Route path='/' exact element={<Home />} />
            <Route path='/quienesSomos' exact element={<QuienesSomos />} />
            <Route path='/login' exact element={<Login setShowNav={setShowNav} />} />
            <Route path='/estructurados' element={<Estructurados />} />
            <Route path='/contacto' element={<Contacto />} />
            <Route path='/laminados' element={<Laminados />} />
            <Route path='/spc' element={<Spc />} />
            <Route path='/persianas' element={<Persianas />} />
            <Route path='/pisoAlfombra' element={<PisoAlfombra />} />
            <Route path='/panelesExterior' element={<PanelesExterior />} />
            <Route path='/panelesInterior' element={<PanelesInterior />} />
            <Route path='/productoimagenes/:id/:data/:nombre' element={<ProductoImagenes />} />
            {/* <Route path='/dashboard' element={<Login setShowNav={setShowNav} />} /> */}

          </Routes>
          <FloatingWhatsApp
            style={{ display: "absolute", bottom: "0", right: "0" }}
            accountName="Pisos y Diseños Floresta"
            statusMessage="Usualmente respondemos en 5 minutos"
            chatMessage="Hola, en que podemos ayudarte?"
            placeholder='Escribenos un mensaje'
            allowClickAway="true"
            avatar={LogoWa}
            phoneNumber="+573174026747"
            notificationLoop={2}

          />

        </div>
        <Footer />


      </BrowserRouter>
        :
        <MaintenancePage />}
    </>





  );
}

export default App;
