import React from 'react'

import Card from './Card';

const Cards = ({ blogs, titulo, dataFrom, imagenes }) => {



  return (
    <div className=' h-auto w-full  bg-black ' >

      <div className=' gap-y-10 mt-5 p-3 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 w-full place-items-center  mx-auto bg-black  '>
        {blogs &&
          blogs.map((blog, index) => (


            <Card blog={blog} index={index} titulo={titulo} dataFrom={dataFrom} imagenes={imagenes} key={index} />




          ))
        }


      </div>

    </div>
  )
}

export default Cards