import React from 'react'
import { MdDelete } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi'
import { useState } from 'react';
import Modal from './Modal';
import EditModal from './EditModal';


const CardsDash = ({ blogs, nombredb, imagenes }) => {

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [blogtitle, setBlogTitle] = useState('');
  const [blogprice, setBlogPrice] = useState('');
  const [blogdescripcion, setBlogdescripcion] = useState('');
  const [blogid, setBlogId] = useState('');
  const [blogimagenes, setBlogImagenes] = useState([]);
  const [medidas, setMedidas] = useState('');
  const [junta, setJunta] = useState('');
  const [grosor, setGrosor] = useState('');
  const [trafico, setTrafico] = useState('');

  const handleEditButton = (blog) => {
    const imagesElement = [];
    imagenes.forEach((imagen, index) => {
      if (imagen.product_id === blog.id) {
        imagesElement.push(imagen.image_url);
        // Your code here using 'imagen' and 'index'
      }
    });
    setBlogImagenes(imagesElement);





    setShowEditModal(true)
    setBlogTitle(blog.titulo)
    setBlogId(blog.id)
    setBlogPrice(blog.precio)
    setBlogdescripcion(blog.descripcion)
    // setBlogImagenes(imagenes[index].image_url);
    setMedidas(blog.medidas)
    setGrosor(blog.grosor)
    setJunta(blog.junta)
    setTrafico(blog.trafico)

  }
  return (
    <div className=' gap-y-5 mt-5 p-3 grid  lg:grid-cols-4 md:grid-cols-2 grid-cols-1 w-full place-items-center  mx-auto bg-black  '>
      {blogs &&
        blogs.map((blog, index) => (
          <div className='w-[300px] relative bg-[#212123] h-[400px] ' key={index}>
            <div className='h-[50%]'>
              {/* {imagenes[index].product_id === blog.id && (

                <img src={imagenes[index].image_url} className="h-full w-full" alt='piso Laminado'></img>
                
              )} */}


            </div>
            <div className='h-1/2 px-5 pt-5 '>
              <h4 className='text-[#a17e67] text-sm'>Laminado</h4>
              <h1 className='text-white font-light text-3xl'>{blog.titulo}</h1>
              {/* <p className='text-md w-full text-white font-normal mt-1'>{blog.descripcion}</p> */}
              <p className='text-white mt-5'>{blog.precio}/m2</p>

            </div>
            <div className='absolute flex p-3 gap-3 bottom-0 right-0 bg-blue '>
              <MdDelete
                onClick={() => {
                  setShowModal(true)
                  setBlogTitle(blog.titulo)
                  setBlogId(blog.id)

                }} className='text-red-500 text-3xl cursor-pointer transform duration-500 hover:scale-12' />
              <FiEdit
                onClick={() => {

                  handleEditButton(blog);

                }}
                className='text-white text-3xl cursor-pointer transform duration-500 hover:scale-12' />

            </div>

          </div>




        ))
      }
      {showModal && (
        <>
          <Modal setShowModal={setShowModal} blogtitle={blogtitle} blogid={blogid} nombredb={nombredb} />

        </>
      )}
      {showEditModal && (
        <>
          <EditModal setShowEditModal={setShowEditModal} blogtitle={blogtitle} blogid={blogid} nombredb={nombredb} blogdescripcion={blogdescripcion} blogprice={blogprice} blogimagenes={blogimagenes} medidas={medidas} trafico={trafico} junta={junta} grosor={grosor} />
        </>
      )}


    </div>
  )
}

export default CardsDash