import React from 'react'
import { Link } from 'react-router-dom'

import { BsCheck } from 'react-icons/bs';

const Card = ({ blog, index, titulo, dataFrom, imagenes }) => {
    const filteredImages = imagenes.filter(image => image.product_id === blog.id);
    const imageURLs = filteredImages.map(image => image.image_url);
    const prueba = imageURLs[0]
    const ispanelesInterior = titulo === "paneles interior";
    const isTrueTitulo = titulo === "Piso laminado" || titulo === "Piso Alfombra" || titulo === "Piso spc" || titulo === "Piso estructurado";






    return (



        <div className={isTrueTitulo ? 'w-full md:w-[90%] bg-[#212123] md:h-[500px]  duration-200 ease-in transform h-[600px]  ' : 'w-full md:w-[90%] bg-[#212123] md:h-[400px]  duration-200 ease-in transform h-[600px] '} key={index}>

            <Link to={`/productoimagenes/${blog.id}/${dataFrom}/${blog.titulo}`}>
                <div className={isTrueTitulo ? 'md:h-[50%] h-[50%] bg-cover bg-center' : 'md:h-[60%] h-[60%] bg-cover bg-center'} >
                    <img src={prueba} loading='lazy' className="h-full w-full" alt={titulo} />

                </div>
            </Link>
            <div className='h-[30%] px-3 pt-5 '>
                <h4 className='text-[#a17e67] text-sm'>{titulo}</h4>
                <h1 className='text-white font-light text-2xl'>{blog.titulo}</h1>
                <p className='text-md text-white font-normal mt-1'>{blog.trafico}</p>

                {isTrueTitulo &&
                    <div className='py-1'>
                        <span className='flex'> <BsCheck className='text-2xl' /> {blog.grosor}</span>
                        <span className='flex'> <BsCheck className='text-2xl' /> {blog.medidas}</span>
                        <span className='flex'> <BsCheck className='text-2xl' /> {blog.junta}</span>




                    </div>
                }
                <p className="text-white mt-5 text-xl font-light">
                    ${blog.precio} {ispanelesInterior ? 'Precio Tira' : <>m<sup>2</sup></>}
                </p>



            </div>

        </div>
    )
}

export default Card