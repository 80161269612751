import React from 'react'
import PagesCarrousel from '../components/pages/PagesCarrousel'
import { useEffect } from 'react'


import { useState } from 'react';
import Cards from '../components/Cards';
import axios from 'axios';
import Loader from '../components/Loader';

const PanelesInterior = () => {
  const [blogs, setBlogs] = useState([])
  const [imagenes, setImagenes] = useState([])
  const isReady = blogs.length !== 0;
  useEffect(() => {
    const obtenerDatos = async () => {
      const data = {
        "product": "paneles_interior"
      }
      const datos = await axios.get(`https://pisos-parador-backend.onrender.com/api/getproducts`, {
        params: data
      })
      const imagenes = await axios.get(`https://pisos-parador-backend.onrender.com/api/getImages`, {
        params: data
      })

      setBlogs(datos.data);
      setImagenes(imagenes.data);




    }
    obtenerDatos();


  }, [setBlogs, setImagenes])
  return (
    <>
      {isReady ? (
        <div className='h-auto mt-16 bg-black text-white'>
          <PagesCarrousel />
          <h1 className='text-white text-5xl font-light text-center pt-5'>Paneles Interior</h1>
          <Cards blogs={blogs} imagenes={imagenes} titulo="paneles interior" dataFrom="paneles_interior" />

        </div>

      ) : (

        <Loader />

      )}
    </>

  )
}

export default PanelesInterior