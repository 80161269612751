import React from 'react'
import persianas from '../../assets/persianas.jpg'
import pisosLaminados from '../../assets/pisoLaminado.jpg'
import pisosEstructurados from '../../assets/pisoEstrcutrado.jpg'
import pisoAlfombra from '../../assets/tipoAlfombra.jpg'
import panelesExterior from '../../assets/panelesExteriores.jpg'
import panelesInterior from '../../assets/panelesInteriores.jpg'
import PisoSpc from '../../assets/pisospc.jpg'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"


const NuestrosProductos = () => {
  const [persianas1, setPersianas1] = useState(false);
  const [laminado, setLaminado] = useState(true);
  const [estructurado, setEstructurado] = useState(false);
  const [alfombra, setAlfombra] = useState(false);
  const [spc, setSpc] = useState(false);
  const [panelInterior, setPanelInterior] = useState(false);
  const [panelExterior, setPanelExterior] = useState(false);
  let boxVariants = {};
  const isMobile = window.innerWidth < 768; //Add the width you want to check for here (now 768px)
  if (!isMobile) {
    boxVariants = {
      initial: { x: -400 },
      whileInView: { x: 170 },


    };
  }
  if (isMobile) {
    boxVariants = {

      initial: { x: -400 },
      whileInView: { x: 0 },




    };
  }
  return (
    <div className='lg:h-screen  h-auto pt-10 pb-20 md:pt-0 md:pb-0 flex md:w-full w-full bg-black'>
      <div className=' lg:flex-1 w-full text-center'>
        <h1 className='text-[#DDDDDD] text-3xl md:py-24 py-10 font-light'>Nuestros Productos</h1>
        <ul className="text-white lg:text-4xl text-3xl font-light  flex flex-col items-center">
          <motion.div
            variants={boxVariants}
            initial="initial"
            whileInView="whileInView"
            transition={{
              ease: [0.6, 0.01, -0.05, 0.9],
              duration: 1.6,
            }}
            className="w-full"
          >
            <li className='pb-5 cursor-pointer transform transition duration-500 hover:scale-110 md:w-1/2 w-full' onMouseEnter={() => { setLaminado(true) }} ><Link to="/laminados">pisos laminados</Link></li>
            <li className='pb-5 cursor-pointer transform transition duration-500 hover:scale-110 md:w-1/2 w-full '
              onMouseEnter={() => {
                setEstructurado(true)
                setLaminado(false)
              }}
              onMouseLeave={() => {
                setEstructurado(!estructurado)
                setLaminado(true)

              }}>
              <Link to="/estructurados">pisos estructurados</Link>
            </li>
            <li className='pb-5 cursor-pointer transform transition duration-500 hover:scale-110 md:w-1/2 w-full '
              onMouseEnter={() => {
                setAlfombra(true)
                setLaminado(false)
              }}
              onMouseLeave={() => {
                setAlfombra(!alfombra)
                setLaminado(true)
              }}
            ><Link to="/pisoAlfombra">piso tipo alfombra</Link>
            </li>

            <li className='pb-5 cursor-pointer transform transition duration-500 hover:scale-110 md:w-1/2 w-full '
              onMouseEnter={() => {
                setPanelInterior(true)
                setLaminado(false)
              }}
              onMouseLeave={() => {
                setPanelInterior(!panelInterior)
                setLaminado(true)
              }}>
              <Link to="/panelesInterior">paneles interiores</Link>
            </li>
            <li className='pb-5 cursor-pointer transform transition duration-500 hover:scale-110 md:w-1/2 w-full '
              onMouseEnter={() => {
                setPersianas1(true)
                setLaminado(false)
              }}
              onMouseLeave={() => {
                setPersianas1(!persianas1)
                setLaminado(true)
              }}>
              <Link to="/persianas">persianas</Link>
            </li>
            <li className='pb-5 cursor-pointer transform transition duration-500 hover:scale-110 md:w-1/2 w-full '
              onMouseEnter={() => {
                setSpc(true)
                setLaminado(false)
              }}
              onMouseLeave={() => {
                setSpc(!spc)
                setLaminado(true)
              }}>
              <Link to="/spc">piso SPC</Link>
            </li>
          </motion.div>
        </ul>
      </div>
      <div className=' md:flex md:flex-1 h-[90%] bg-black  text-white justify-center hidden '>

        {persianas1 && (
          <img src={persianas} className="h-full w-[80%]" alt='persianas'></img>

        )}
        {laminado && (
          <img src={pisosLaminados} className="h-full w-[80%]" alt='pisosLaminados'></img>

        )}
        {estructurado && (
          <img src={pisosEstructurados} className="h-full w-[80%]" alt='pisos estructurados'></img>

        )}
        {alfombra && (
          <img src={pisoAlfombra} className="h-full w-[80%]" alt='piso tipo alfombra'></img>

        )}
        {spc && (
          <img src={PisoSpc} className="h-full w-[80%]" alt='piso spc'></img>

        )}
        {panelInterior && (

          <img src={panelesExterior} className="h-full w-[80%]" alt='paneles interiores'></img>

        )}
        {panelExterior && (
          <img src={panelesInterior} className="h-full w-[80%]" alt='paneles exterior'></img>

        )}










      </div>
    </div>
  )
}

export default NuestrosProductos