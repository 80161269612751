import React from 'react'
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import MostrarProducto from '../components/ProductoImagenes/MostrarProducto';
import axios from 'axios';

const ProductoImagenes = () => {

  const params = useParams();
  const id = params.id;
  const data = params.data;
  const nombre = params.nombre;
  const [blogs, setBlogs] = useState();
  const [imagenes, setImagenes] = useState([])
  const isReady = blogs !== undefined;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const obtenerDatos = async () => {
      const data1 = {
        "product": data,
        "id": id
      }
      const datos = await axios.get(`https://pisos-parador-backend.onrender.com/api/getproduct`, {
        params: data1
      })
      const imagenes = await axios.get(`https://pisos-parador-backend.onrender.com/api/getimagesProduct`, {
        params: data1
      })

      setBlogs(datos.data);
      setImagenes(imagenes.data);




    }
    obtenerDatos();

  }, [setBlogs, setImagenes, data, id])


  // console.log(blogs);
  return (
    <>
      {isReady ? (
        <MostrarProducto blogs={blogs} imagenes={imagenes} nombre={nombre} />

      ) : (
        <div className='h-screen bg-black w-full flex justify-center items-center'>
          <div class="loader">
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProductoImagenes