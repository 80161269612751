import React from 'react'
import { useEffect } from 'react'

import { db } from '../../../firebase/firebaseConfig';
import {   getDocs } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { useState } from 'react';
import CardsDash from './CardsDash';
const PisoAlfombraDash = () => {
  const [blogs,setBlogs]=useState([])
  const isready = blogs.length !== 0
  useEffect(()=>{
      const obtenerDatos = async() =>{
        const datos = await getDocs(collection(db,"piso_alfombra"));
        const saveFirebaseTodos = [];
        datos.forEach((documento) =>{
          const data = documento.data()
            data.id = documento.id
            saveFirebaseTodos.push(data);
        });
        setBlogs(saveFirebaseTodos);
  
        
  
  
      }
      obtenerDatos();
      
  
    },[])
  return (
    <>
    {isready ? (
      <div className=' h-auto w-full  bg-black '>
      <h1 className='text-3xl  text-white px-10 py-4 font-normal'> Piso Alfombra Disponibles</h1>
      <CardsDash blogs={blogs} nombredb = "piso_alfombra"/> 
  
      </div>
    ) : (
        <div className='h-screen bg-black w-full flex justify-center items-center'>
        <div class="loader">
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
        </div>
       
     </div>
    )
    
    
    }
    </>
  )
}

export default PisoAlfombraDash