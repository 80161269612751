import React from 'react'
import { useEffect } from 'react'
import axios from 'axios';

import { useState } from 'react';
import CardsDash from './CardsDash';

const PisoLaminadoDash = () => {
  const [blogs, setBlogs] = useState([])
  const [imagenes, setImagenes] = useState([])
  const isReady = blogs.length !== 0;

  useEffect(() => {
    const obtenerDatos = async () => {
      const data = {
        "product": "pisos_laminados"
      }
      const datos = await axios.get(`https://pisos-parador-backend.onrender.com/api/getproducts`, {
        params: data
      })
      const imagenes = await axios.get(`https://pisos-parador-backend.onrender.com/api/getImages`, {
        params: data
      })

      setBlogs(datos.data);
      setImagenes(imagenes.data);







    }
    obtenerDatos();


  }, [setBlogs, setImagenes])

  return (
    <>
      {isReady ?
        (<div className=' h-auto w-full  bg-black '>
          <h1 className='text-3xl  text-white px-10 py-4 font-normal'> Pisos Laminados Disponibles</h1>
          <CardsDash blogs={blogs} imagenes={imagenes} nombredb="pisos_laminados" />

        </div>
        ) :
        (<div className='h-screen bg-black w-full flex justify-center items-center'>
          <div className="loader">
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
          </div>

        </div>)}
    </>

  )
}

export default PisoLaminadoDash