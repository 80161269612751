import React from 'react'
import { useState } from 'react'
import close from '../../../assets/close2.png'
import open2 from '../../../assets/burguerMenu.png'
import { Link } from 'react-router-dom'

const NavbarDashBoard = ({userSignOut, setpisoAlfombra , setPanelesExterior,setPanelesInterior, setPersianas,setPisoLaminado, setPisoEstructurado, setPisoSpc, setHomePage}) => {
const [open, setOpen] = useState(false);
const openMenu = () =>{
    setOpen(!open);
}
 
  return (
    
    <div className='bg-black h-auto relative w-full flex justify-between items-center'>
        <div className=' flex-1 cursor-pointer' >
            <img src={open ? `${close}` :`${open2}`} onClick={openMenu} className="p-2 h-full w-12" alt='close and open icon'></img>
        </div>


        <h1 className='text-3xl hidden md:flex flex-1 font-medium text-white text-center cursor-pointer '>
          Panel de control
        </h1>
          <div className='flex gap-3 cursor-pointer'>
            <button className=' w-auto  px-5 py-1 bg-[#a17e67] rounded-lg text-white transition hover:scale-105'
              onClick={()=>{
            setHomePage(true)
            setPisoLaminado(false)
            setPanelesExterior(false)
            setPanelesInterior(false)
            setPisoSpc(false)
            setPersianas(false)
            setpisoAlfombra(false)
            setPisoEstructurado(false)
            setOpen(false)
            }}
            >
            Añadir Productos
            </button>

            <button className=' w-auto px-5 py-1 bg-[#a17e67] rounded-lg text-white transition hover:scale-105' onClick={userSignOut}>Cerrar Sesion</button>

          </div>
        
        <ul
          className={`   top-12 absolute left-0 bg-white z-50 rounded shadow-2xl flex flex-col items-center pt-10 gap-10  w-[300px] h-screen   transition-all duration-500 ease-in ${
            open ? "left-0 " : "left-[-300px]"
          }`}
        >
        <li className=" text-xl cursor-pointer text-gray-800 hover:text-gray-400 duration-500 "
            onClick={()=>{
                setPisoLaminado(false)
                setPanelesExterior(false)
                setPanelesInterior(false)
                setPisoSpc(false)
                setPersianas(false)
                setpisoAlfombra(false)
                setPisoEstructurado(false)
                setOpen(!open)
                setHomePage(false);
                window.location.reload();
                
                
                
            }}>
            <Link to="/">
            Pagina inicial
            </Link>

                
              
           </li>
           <li className=" text-xl cursor-pointer text-gray-800 hover:text-gray-400 duration-500 "
            onClick={()=>{
                setPisoLaminado(true)
                setPanelesExterior(false)
                setPanelesInterior(false)
                setPisoSpc(false)
                setPersianas(false)
                setpisoAlfombra(false)
                setPisoEstructurado(false)
                setOpen(!open)
                setHomePage(false);
                
            }}>
                Piso Laminado
              
           </li>
           <li className=" text-xl cursor-pointer text-gray-800 hover:text-gray-400 duration-500"
             onClick={()=>{
                setPisoLaminado(false)
                setOpen(!open)
                setPanelesExterior(false)
                setPanelesInterior(false)
                setPisoSpc(false)
                setPersianas(false)
                setpisoAlfombra(false)
                setPisoEstructurado(true)

                setHomePage(false)            
}}>
           
                Piso Estructurado
              
           </li>
           <li className=" text-xl cursor-pointer text-gray-800 hover:text-gray-400 duration-500 "
             onClick={()=>{
                setPisoLaminado(false)
                setOpen(!open)
                setPanelesExterior(false)
                setPanelesInterior(false)
                setPisoSpc(true)
                setPersianas(false)
                setpisoAlfombra(false)
                setPisoEstructurado(false)
                setHomePage(false)
            }}>
                Piso Spc
              
           </li>
           <li className=" text-xl cursor-pointer text-gray-800 hover:text-gray-400 duration-500 "
             onClick={()=>{
                setPisoLaminado(false)
                setOpen(!open)
                setPanelesExterior(false)
                setPanelesInterior(false)
                setPisoSpc(false)
                setPersianas(false)
                setpisoAlfombra(true)
                setPisoEstructurado(false)
                setHomePage(false)
            }}>
                Piso Alfombra
              
           </li>
           <li className=" text-xl cursor-pointer text-gray-800 hover:text-gray-400 duration-500 "
             onClick={()=>{
                setPisoLaminado(false)
                setOpen(!open)
                setPanelesExterior(false)
                setPanelesInterior(false)
                setPisoSpc(false)
                setPersianas(true)
                setpisoAlfombra(false)
                setPisoEstructurado(false)
                setHomePage(false)
            }}>
               Persianas
              
           </li>
           <li className=" text-xl cursor-pointer text-gray-800 hover:text-gray-400 duration-500 "
             onClick={()=>{
                setPisoLaminado(false)
                setOpen(!open)
                setPanelesExterior(false)
                setPanelesInterior(true)
                setPisoSpc(false)
                setPersianas(false)
                setpisoAlfombra(false)
                setPisoEstructurado(false)
                setHomePage(false)
            }}>
                Paneles Interior
              
           </li>
           <li className=" text-xl cursor-pointer text-gray-800 hover:text-gray-400 duration-500 "
             onClick={()=>{
                setPisoLaminado(false)
                setOpen(!open)
                setPanelesExterior(true)
                setPanelesInterior(false)
                setPisoSpc(false)
                setPersianas(false)
                setpisoAlfombra(false)
                setPisoEstructurado(false)
                setHomePage(false)
            }}>
                Paneles Exterior
              
           </li>
         
        </ul>

    </div>
  
  )
}

export default NavbarDashBoard