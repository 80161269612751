import React from 'react'
import { BsTelephoneFill } from 'react-icons/bs';
import { FaLocationArrow } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

import { Link } from 'react-router-dom';
const Footer = () => {
    return (

        <footer className="bg-black ">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8 text-white">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <Link to="/">
                            <span className="self-center text-xl md:text-3xl  font-semibold whitespace-nowrap dark:text-white">PISOS Y DISEÑOS FLORESTA</span>
                        </Link>
                        <div className='mt-5 text-xl font-light md:py-3 '>
                            <p className='text-white flex items-center gap-2'><span className='text-red-500'><FaLocationArrow /></span> Calle 94A #67A-74, Local 25 </p>
                            <p className='text-white'> </p>
                            <a href="mailto:pisosydisenosfloresta@gmail.com" className='cursor-pointer text-white flex items-center gap-2'><MdEmail />  pisosydisenosfloresta@gmail.com</a>
                            <a href='tel:+573174026747' className='cursor-pointer '><p className='text-white flex  items-center gap-2 mt-2'> <span className=' text-green-400'><BsTelephoneFill /> </span> (317)-402-6747</p></a>
                        </div>






                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold  uppercase text-white">Compañia</h2>
                            <ul className="text-gray-300 font-medium">
                                <li className="mb-4">
                                    <a href="/quienesSomos" className="hover:underline">Quienes Somos</a>
                                </li>
                                <li>
                                    <a href="/contacto" className="hover:underline">Contactanos</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold  uppercase text-white">Siguenos</h2>
                            <ul className="text-gray-300 font-medium">
                                <li className="mb-4">
                                    <a href="https://instagram.com/pisosparadorcolombia?igshid=MjEwN2IyYWYwYw==" className="hover:underline ">instagram</a>
                                </li>

                            </ul>
                        </div>
                        {/* <div>
                            <h2 className="mb-6 text-sm font-semibold text-white uppercase ">Legal</h2>
                            <ul className="text-gray-300 font-medium">
                                <li className="mb-4">
                                    <a href="www.google.com" className="hover:underline">Politicas de Privacidad</a>
                                </li>
                                <li>
                                    <a href="www.google.com" className="hover:underline">Terminos y condiciones</a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-white sm:text-center ">© 2023 PISOS Y DISEÑOS FLORESTA™. Derechos reservados.
                    </span>
                    <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">

                        <a href="https://instagram.com/pisosparadorcolombia?igshid=MjEwN2IyYWYwYw==" className="text-white ">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" /></svg>
                            <span className="sr-only">Instagram page</span>
                        </a>


                    </div>
                </div>
            </div>
        </footer>


    )
}

export default Footer