import React from 'react'

import VideoQuienesSomos from '../assets/videos/newVideo.mp4'
import ImagenLaminado from '../assets/Roble gris mistral.jpg'
import ImagenMision from '../assets/Roble Nova encalado claro.jpg'
import ImagenVision from '../assets/Desván de roble natural.jpg'


const QuienesSomos = () => {
  return (
    <div className='h-auto mt-16'>
      <div className='lg:h-[80vh] h-screen relative  ' >
        <div className='relative h-full'>
          <video id="myVideo" autoplay="autoplay" playsinline="playsinline" muted="muted" loop="loop" style={{ width: '100%', height: '100%', objectFit: 'cover' }}>

            <source src={VideoQuienesSomos} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <h1 className='absolute md:top-[40%] top-[30%] right-[0%] text-center  w-auto font-normal text-white text-5xl'>Descubre Nuestra Trayectoria en Soluciones para Espacios Interiores y Exteriores</h1>


        </div>


      </div>
      <div className='h-auto bg-black  flex md:flex-row flex-col'>
        <div className='flex-1 md:p-10 px-3 pt-3 '>
          <div className=' lg:p-0 p-5'>
            <h1 className='text-3xl font-medium text-[#a17e67]'>Nuestra Historia</h1>
            <p className='text-white mt-5 text-xl font-normal'>Con más de 20 años de experiencia en la importación y comercialización de acabados para la construcción, Pisos y Diseños Floresta ofrece una amplia gama de productos de alta calidad. Desde pisos laminados y de madera hasta cortinas y accesorios, brindamos soluciones integrales respaldadas por estándares superiores. Importamos y despachamos pedidos a nivel nacional, y nuestro equipo de ventas está listo para atender todas tus consultas. Confía en Parador Colombia para transformar tus espacios con estilo y excelencia.</p>
          </div>

        </div>
        <div className='flex-1 md:p-10 pt-5'>
          <img src={ImagenLaminado} className="lg:rounded-xl h-[400px] w-full" alt='piso Aleman Laminado'></img>

        </div>



      </div>
      <div className='h-auto bg-black  flex md:flex-row flex-col-reverse '>

        <div className='flex-1 md:p-10 pt-5'>
          <img src={ImagenMision} className="lg:rounded-xl h-[400px] w-full" alt='piso Aleman Laminado'></img>

        </div>
        <div className='flex-1 md:p-10 px-3 pt-3 '>
          <div className=' lg:p-0 p-5'>
            <h1 className='text-3xl font-medium text-[#a17e67]'>Nuestra Mision</h1>
            <p className='text-white mt-5 text-xl font-normal'>Ofrecer soluciones de acabados superiores para la construcción, brindando productos de calidad, diseño innovador y servicio excepcional. Transformamos espacios con estilo y excelencia, siendo el socio confiable en la materialización de proyectos.</p>
          </div>

        </div>



      </div>
      <div className='h-auto bg-black  flex md:flex-row flex-col'>


        <div className='flex-1 md:p-10 px-3 pt-3 '>
          <div className=' lg:p-0 p-5'>
            <h1 className='text-3xl font-medium text-[#a17e67]'>Nuestra Vision</h1>
            <p className='text-white mt-5 text-xl font-normal'>Convertirnos en el referente indiscutible en el mercado de acabados para la construcción. Buscamos ser reconocidos como líderes en calidad, diseño y servicio, ofreciendo soluciones vanguardistas que inspiren y transformen espacios. A través de la innovación constante y la satisfacción del cliente, aspiramos a ser la elección preferida de arquitectos, diseñadores y constructores en la búsqueda de acabados excepcionales.</p>
          </div>

        </div>
        <div className='flex-1 md:p-10 pt-5'>
          <img src={ImagenVision} className="lg:rounded-xl h-[400px] w-full" alt='piso Aleman Laminado'></img>

        </div>



      </div>



    </div>
  )
}

export default QuienesSomos