import React, { useState } from 'react'
import BurguerMenu from '../assets/burguerMenu.png'
import CloseBlack from '../assets/closeBlack.png'

import ProfileIcon from '../assets/user.png'
import ProfileIconBlack from '../assets/userBlack.png'
import { Link } from 'react-router-dom'
import Menu from './BurguerMenu'
import '../Navbar.css'

const Navbar = () => {

  const [openmenu, setOpenMenu] = useState(false);



  return (
    <div className={openmenu ? ' h-16 border-b border-black  bg-white flex justify-between text-black items-center fixed top-0 left-0 right-0  z-50 w-full' : ' z-50 w-full fixed top-0 left-0 right-0  h-16 bg-black  border-b border-white flex justify-between text-white items-center'}>
      <div onClick={() => { setOpenMenu(!openmenu) }} className='h-full flex-1 flex justify-start  py-5 px-3 '>
        <button className={openmenu ? `animated btn-nav` : `btn-nav`}>
          <div className={openmenu ? "animated bar arrow-top-r" : "bar arrow-top-r"}></div>
          <div className={openmenu ? "animated bar arrow-middle-r" : "bar arrow-middle-r"}></div>
          <div className={openmenu ? "animated bar arrow-bottom-r" : "animated bar arrow-bottom-r"}></div>
        </button>

      </div>
      <Link to="/" onClick={() => { setOpenMenu(false) }}>
        <div className=' flex-2 justify-center flex font-black tracking-wider md:text-2xl text-md'>
          PISOS Y DISEÑOS FLORESTA
        </div>

      </Link>
      <ul className=' flex-1 list-none flex  h-5 gap-5 mr-5  items-center justify-end '>

        <li className='cursor-pointer h-6'>
          <Link to="/login">
            {openmenu ? <img src={ProfileIconBlack} className="w-6 h-full" alt="ProfileIcon" /> : <img src={ProfileIcon} className="w-6 h-full" alt="ProfileIcon" />}

          </Link>
        </li>
      </ul>
      {openmenu && (
        <Menu stateOpen={openmenu} setOpenMenu={setOpenMenu} />

      )}


    </div>
  )
}

export default Navbar