import React from 'react'


import { BsCheck } from 'react-icons/bs';
import { useState } from 'react';




const MostrarProducto = ({ blogs, imagenes, nombre }) => {


  const [index, setIndex] = useState(0);




  return (
    <div className=' lg:h-[700px] w-full mt-16 h-auto relative bg-white flex lg:flex-row flex-col'>
      <div className="box flex-1 z-10  ">


        <img src={imagenes[0].image_url && imagenes[index].image_url} className="product-detail-image lg:w-[90%] lg:h-[550px] w-full" alt={nombre} />


        <div className='small-images-container overflow-x-scroll lg:ml-5 ml-1 '>
          {imagenes?.map((item, i) => (
            <img key={i} src={item.image_url} className={i === index ? 'small-image selected-image' : 'small-image'} onMouseEnter={() => setIndex(i)} alt={nombre} />
          ))}
        </div>





      </div>
      <div className='lg:w-[40%] w-full h-auto pb-20 flex flex-col  '>
        <div className='px-5 pt-5  '>

          <h1 className='text-[#a17e67] text-sm'>{nombre}</h1>
          <h1 className=' text-5xl font-light mt-3'>{blogs[0].titulo}</h1>

          <p className='  mt-5 ml-1 cursor-pointer font-bold transform duration-500 hover:scale-105'>Detalles tecnicos</p>

          <div>
            <span className='flex'> <BsCheck className='text-2xl' /> {blogs[0].grosor}</span>
            <span className='flex'> <BsCheck className='text-2xl' /> {blogs[0].medidas}</span>
            <span className='flex'> <BsCheck className='text-2xl' /> {blogs[0].junta}</span>




          </div>
          <p className='font-normal  text-base mt-5'>{blogs[0].descripcion}</p>
          <div className='pt-5 '>
            <div>precio:</div>
            <div className='text-4xl'>${blogs[0].precio} /m<sup>2</sup></div>

          </div>


        </div>


      </div>
    </div>
  )
}

export default MostrarProducto