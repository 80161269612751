import React from 'react'

const Loader = () => {
    return (
        <div className='h-screen bg-black w-full flex justify-center items-center'>
            <div className="loader">
                <div className="loader-square"></div>
                <div className="loader-square"></div>
                <div className="loader-square"></div>
                <div className="loader-square"></div>
                <div className="loader-square"></div>
                <div className="loader-square"></div>
                <div className="loader-square"></div>
            </div>
        </div>
    )
}

export default Loader