import React from 'react'
import { motion } from 'framer-motion';
import { GrClose } from 'react-icons/gr'
import { doc, updateDoc } from "firebase/firestore";
import { db } from '../../../firebase/firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';

const ImageBox = ({ imageUrl, onRemove }) => {
    return (
        <div className='image-box' style={{ position: "relative" }}>
            <button onClick={onRemove} className='remove-button' style={{ position: "absolute", right: "3px", top: "4px", color: "white" }}>
                X
            </button>
            <img src={imageUrl} alt='Product' className='image h-[150px] w-[150px]' />

        </div>
    );
};

const EditModal = ({ setShowEditModal, blogtitle, blogid, nombredb, blogprice, blogdescripcion, blogimagenes, medidas, grosor, junta, trafico }) => {
    const [titulo, setTitulo] = useState(blogtitle)
    const [precio, setPrecio] = useState(blogprice)
    const [medidas1, setMedidas] = useState(medidas);
    const [junta1, setJunta] = useState(junta);
    const [grosor1, setGrosor] = useState(grosor);
    const [trafico1, setTrafico] = useState(trafico);
    const [descripcion, setDescripcion] = useState(blogdescripcion);
    const [images, setImages] = useState(blogimagenes);

    const handleRemoveImage = (index) => {
        const deletedImage = images[index]; // Get the image before removing it
        console.log('Deleting image:', deletedImage);

        // Create a copy of the images array and remove the image at the specified index
        const updatedImages = [...images];
        updatedImages.splice(index, 1);

        setImages(updatedImages);
    };


    const blogimagenestest = images.map((imageUrl, index) => (
        <ImageBox
            key={index}
            imageUrl={imageUrl}
            onRemove={() => handleRemoveImage(index)}
        />
    ));


    const EditProduct = async (e) => {
        e.preventDefault()
        try {
            if (nombredb === "pisos_laminados" || nombredb === "pisos_estructurados" || nombredb === "piso_spc " || nombredb === "piso_alfombra") {
                await updateDoc(doc(db, nombredb, blogid), {
                    titulo: titulo,
                    precio: precio,
                    descripcion: descripcion,
                    medidas: medidas1,
                    junta: junta1,
                    grosor: grosor1,
                    trafico: trafico1

                });
            }
            else {
                await updateDoc(doc(db, nombredb, blogid), {
                    titulo: titulo,
                    precio: precio,
                    descripcion: descripcion
                });

            }

            toast.success('se actualizo el producto con exito', {
                position: toast.POSITION.TOP_CENTER
            });
            setTimeout(() => {
                setShowEditModal(false);
                window.location.reload();
            }, 3000);

        } catch (error) {
            console.log("theres an error: " + error)

        }
    }


    return (
        <motion.div
            className="px-3 lg:px-0  flex justify-center items-center overflow-x-hidden shadow-xl overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-modal-opacity "
            initial={{ y: "300px", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            onClick={() => setShowEditModal(false)}

        >
            <div className="relative md:w-auto w-full my-6 mx-auto max-w-3xl ">

                <div className="border-0 rounded-lg shadow-lg relative flex flex-col px-5 py-5 w-full bg-white outline-none focus:outline-none">
                    <div className="absolute w-4 h-4 cursor-pointer top-3 right-5  " onClick={() => setShowEditModal(false)}>
                        <GrClose />

                    </div>
                    <h1 className='text-2xl'>Editar <b>{blogtitle}</b> </h1>
                    <div className='flex md:flex-row flex-col gap-5 mt-5'>
                        <div className='flex flex-col flex-1'>
                            <label>Nombre</label>
                            <input type='text' value={titulo} name="nombre" className='rounded' placeholder='Nombre Del Producto' onChange={(e) => { setTitulo(e.target.value) }} ></input>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <label>Precio</label>
                            <input type='text' value={precio} name="precio" className='rounded' placeholder='Precio Por M2' onChange={(e) => { setPrecio(e.target.value) }} ></input>
                        </div>

                    </div>
                    <div className='grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2 gap-5 mt-5'>
                        <div className='flex flex-col flex-1'>
                            <label>trafico</label>
                            <input type='text' value={trafico1} name="trafico" className='rounded' placeholder='trafico' onChange={(e) => { setTrafico(e.target.value) }} ></input>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <label>grosor</label>
                            <input type='text' value={grosor1} name="grosor" className='rounded' placeholder='grosor Producto' onChange={(e) => { setGrosor(e.target.value) }}></input>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <label>junta</label>
                            <input type='text' value={junta1} name="junta" className='rounded' placeholder='junta del producto' onChange={(e) => { setJunta(e.target.value) }} ></input>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <label>Medidas</label>
                            <input type='text' value={medidas1} name="medidas" className='rounded' placeholder='medidas del producto' onChange={(e) => { setMedidas(e.target.value) }} ></input>
                        </div>

                    </div>
                    <div className='h-auto w-full flex flex-col mt-5'>
                        <label>Descripcion</label>
                        <textarea value={descripcion} className='min-h-[150px] w-full rounded' placeholder='Descripcion Del Producto' onChange={(e) => { setDescripcion(e.target.value) }} ></textarea>
                    </div>
                    <div className='h-auto w-full flex flex-col mt-5'>
                        <label>Imagenes</label>
                        <div className='w-full h-auto flex gap-5'>
                            {blogimagenestest}
                        </div>
                    </div>
                    <div className='mt-5'>
                        <button onClick={EditProduct} className='bg-[#a17e67] text-white px-10 py-3 rounded transition hover:scale-110'>Editar productos</button>
                    </div>



                </div>
            </div>
            <ToastContainer />

        </motion.div>
    )
}

export default EditModal