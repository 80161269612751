import React from 'react'
import contactoImagen from '../assets/contactoImagen2.jpg'
import ContactoForm from '../components/HomePage/Contacto'

const Contacto = () => {
  return (
    <div className='h-auto mt-16'>

      <div className='lg:h-[70vh] flex justify-center items-center h-[80vh] relative bg-center bg-cover' style={{ backgroundImage: `url(${contactoImagen})` }}>

        <h1 className='text-5xl md:w-1/2 w-full px-5 lg:px-0 mt-32 md:mt-0 text-center  absolute text-white font-normal'>¡Contáctanos y déjanos hacer realidad tus sueños!</h1>
      </div>

      <div className='w-full bg-black py-24 flex justify-center items-center '>

        <div className='lg:w-[60%] w-full bg-red-500'>
          <ContactoForm />
        </div>






      </div>







    </div>
  )
}

export default Contacto