import React from 'react'
import mantenimientoImagen from '../assets/mantenimiento.jpg'

const MaintenancePage = () => {
    return (
        <div class="flex flex-col items-center justify-center h-screen  px-4">
            <h1 class="text-4xl md:text-6xl font-bold text-gray-800 mb-4">
                ¡Volvemos pronto!
            </h1>
            <p class="text-lg md:text-2xl text-gray-600 text-center max-w-xl mb-6">
                Gracias por tu paciencia. En Pisos y Diseños estamos trabajando para mejorar tu experiencia. Actualmente nos encontramos en mantenimiento para ofrecerte lo mejor.
            </p>
            <img src={mantenimientoImagen} alt="Mantenimiento" class="w-64 md:w-80 lg:w-96 object-contain mt-6" />
        </div>

    )
}

export default MaintenancePage