import { signInWithEmailAndPassword } from 'firebase/auth'
import React from 'react'
import { useState } from 'react'
import PisoLaminado from '../../assets/pisoLaminado.jpg'
import { auth } from '../../firebase/firebaseConfig'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const LoginForm = ({ setRedirect }) => {
    const [values, setValues] = useState({});


    const handleLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, values.email, values.password)
            .then(() => {
                setRedirect(true);
            }).catch((error) => {
                toast.error('no tienes acceso a esta cuenta', {
                    position: toast.POSITION.TOP_CENTER
                });
            })

    }
    const handleInputChange = (event) => {
        setValues((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));

    }

    return (
        <>


            <div className='h-screen bg-black flex justify-center items-center'>

                <div className='flex-1 flex justify-center items-center bg-black h-full'>
                    <div className='relative md:w-[70%] w-full md:h-[60%] h-[50%] rounded flex flex-col items-center bg-white'>
                        <h1 className='text-3xl font-medium text-gray-700 pt-5'>Bienvenido de nuevo</h1>

                        <h3 className='text-gray-400'>Bienvenido de nuevo, por favor ingresa tus datos</h3>
                        <form className='w-full px-10 mt-10 ' onSubmit={handleLogin}>
                            <div className='flex flex-col'>
                                <label>Email</label>
                                <input required type="email" name='email' onChange={handleInputChange} className=' rounded-lg border-gray-400 ' placeholder='ingresa tu email'></input>
                            </div>
                            <div className='flex flex-col mt-5'>
                                <label>Contraseña</label>
                                <input required type="password" name='password' onChange={handleInputChange} className=' rounded-lg border-gray-400 ' placeholder='ingresa tu contraseña'></input>
                            </div>
                            <button type='submit' className='w-full mt-5 py-3 bg-[#a17e67] rounded-lg text-white transition hover:scale-105'>ingresar</button>
                        </form>
                        <ToastContainer />


                    </div>
                </div>
                <div className='md:flex-1 md:flex hidden  h-full'>
                    <img src={PisoLaminado} alt="pisoLaminado" className="h-full w-full"></img>
                </div>

            </div>
        </>
    )
}

export default LoginForm