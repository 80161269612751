import React from 'react'
import { Link } from 'react-router-dom'

const BurguerMenu = ({ stateOpen, setOpenMenu }) => {
  const Links =
  {

  }
  return (
    <div className={stateOpen ? "nav-container showNav " : "nav-container hidden2 hideNav"}>
      <ul className='font-medium pb-20 text-xl list-none text-[#a17e67] h-auto flex flex-col justify-center items-center gap-10'>

        <Link onClick={() => { setOpenMenu(!stateOpen) }} to="/quienesSomos">
          <li className='transform hover:scale-110  transition duration-500 ease-in-out cursor-pointer'>Quienes Somos</li>
        </Link>
        <Link onClick={() => { setOpenMenu(!stateOpen) }} to="/estructurados">
          <li className='transform hover:scale-110  transition duration-500 ease-in-out cursor-pointer'>Madera Estructurada</li>
        </Link>
        <Link onClick={() => { setOpenMenu(!stateOpen) }} to="/laminados">
          <li className='transform hover:scale-110  transition duration-500 ease-in-out cursor-pointer'>Pisos Laminados</li>

        </Link>

        <Link onClick={() => { setOpenMenu(!stateOpen) }} to="/pisoAlfombra">
          <li className='transform hover:scale-110  transition duration-500 ease-in-out cursor-pointer'>Piso Tipo Alfombra</li>

        </Link>

        <Link onClick={() => { setOpenMenu(!stateOpen) }} to="/spc">
          <li className='transform hover:scale-110  transition duration-500 ease-in-out cursor-pointer'>Piso SPC</li>

        </Link>

        {/* <Link onClick={()=>{setOpenMenu(!stateOpen)}} to="/panelesExterior">
            <li className='transform hover:scale-110  transition duration-500 ease-in-out cursor-pointer'>Paneles Exterior</li>

          </Link> */}

        <Link onClick={() => { setOpenMenu(!stateOpen) }} to="/panelesInterior">
          <li className='transform hover:scale-110  transition duration-500 ease-in-out cursor-pointer'>Paneles Interior</li>

        </Link>

        <Link onClick={() => { setOpenMenu(!stateOpen) }} to="/persianas">
          <li className='transform hover:scale-110  transition duration-500 ease-in-out cursor-pointer'>Persianas</li>

        </Link>
        <Link onClick={() => { setOpenMenu(!stateOpen) }} to="/contacto">
          <li className='transform hover:scale-110  transition duration-500 ease-in-out cursor-pointer'>Contacto</li>
        </Link>

      </ul>

    </div>
  )
}

export default BurguerMenu