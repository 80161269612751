import React from 'react'
import Carrousel from '../components/HomePage/Carrousel'
import Quote from '../components/HomePage/Quote'
import NuestrosProductos from '../components/HomePage/NuestrosProductos'
import NuestroServicio from '../components/HomePage/NuestroServicio'
import Contacto from '../components/HomePage/Contacto'
import MapSection from '../components/HomePage/MapSection'

const Home = () => {
  function logFormData(formData) {
    for (let entry of formData.entries()) {
      console.log(entry[0] + ':', entry[1]);
    }
  }

  const formData = new FormData();
  formData.append('title', "roble de españa")
  formData.append('description', 'piso para exteriores y interiores tipo aeropuerto')
  formData.append('files', ['file1', 'file2']);

  console.log(logFormData(formData))

  return (
    <>

      <Carrousel />
      <Quote />
      <NuestrosProductos />
      <NuestroServicio />
      {/* <Contacto /> */}
      <MapSection />
    </>

  )
}

export default Home