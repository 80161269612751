import React from 'react'

import { motion } from 'framer-motion'
import Garantia from "../../assets/garantia.png"
import Profesionales from "../../assets/empresario.png"
import Instalacion from "../../assets/instalacion.png"
import Atencion from "../../assets/apoyo.png"

const NuestroServicio = () => {
  let boxVariants = {};
  const isMobile = window.innerWidth < 768; //Add the width you want to check for here (now 768px)
  if (!isMobile) {
    boxVariants = {
      initial: { x: -400 },
      whileInView: { x: 0 },


    };
  }

  return (

    <div className='lg:h-[80vh] bg-black  h-auto py-24 lg:py-0 flex  w-full'>
      <motion.div
        variants={boxVariants}
        initial="initial"
        whileInView="whileInView"
        transition={{
          ease: [0.6, 0.01, -0.05, 0.9],
          duration: 1.6,
        }}
        className="w-full"
      >
        <h1 className='w-full text-center text-white font-light text-4xl pt-5 pb-16'>Nuestros Servicios</h1>
        <div className='grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1  items-center w-full  gap-10 md:gap-0  md:py-0 md:px-0'>
          <div className=' text-white text-center px-12 md:px-0 flex flex-col items-center  rounded  w-full'>
            <div className='w-full flex h-[40%] justify-center items-center'>
              <img src={Atencion} className="h-[80%] w-[30%]" alt="icono" />
            </div>
            <h1 className='text-2xl py-5 font-normal'>Atencion personalizada</h1>
            <p className='md:w-[80%] text-justify font-light  '>Te brindamos atención personalizada para asegurarnos de que encuentres las soluciones perfectas para tus proyectos.</p>

          </div>
          <div className=' text-white text-center px-12 md:px-0  rounded  w-full flex flex-col items-center '>
            <div className='w-full flex h-[40%] justify-center items-center'>
              <img src={Profesionales} className="h-[80%] w-[30%]" alt="icono" />
            </div>
            <h1 className='text-2xl py-5 font-normal'>Operarios Calificados</h1>
            <p className='w-[80%] text-justify font-light'>Contamos con un equipo de operarios altamente calificados, expertos en su oficio, que se dedican a garantizar la excelencia en cada detalle</p>

          </div>
          <div className=' text-white text-center px-12 md:px-0  rounded  w-full flex flex-col items-center '>
            <div className='w-full flex h-[40%] justify-center items-center'>
              <img src={Instalacion} className="h-[80%] w-[30%]" alt="icono" />
            </div>
            <h1 className='text-2xl py-5 font-normal'>Servicio de instalacion</h1>
            <p className='w-[80%] text-justify font-light'>
              Confía en nuestro servicio de instalación profesional para transformar tus espacios con precisión, excelencia y garantía.</p>

          </div>
          <div className=' text-white text-center px-12 md:px-0 rounded  w-full flex flex-col items-center '>
            <div className='w-full flex h-[40%] justify-center items-center'>
              <img src={Garantia} className="h-[80%] w-[30%]" alt="icono" />
            </div>
            <h1 className='text-2xl py-5 font-normal'>Garantia</h1>
            <p className='w-[80%] text-justify font-light'>respaldamos nuestros productos y servicios con una sólida garantía, brindando tranquilidad y confianza a nuestros clientes en cada proyecto</p>

          </div>
        </div>
      </motion.div>
    </div>

  )
}

export default NuestroServicio