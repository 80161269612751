
import React from 'react'
import { useState } from 'react'

import 'react-toastify/dist/ReactToastify.css';
import DashBoard from '../components/Login/DashBoard'
import LoginForm from '../components/Login/LoginForm'


const Login = ({ setShowNav }) => {
  const [redirect, setRedirect] = useState(false);



  return (
    <>

      {redirect ? <DashBoard setShowNav={setShowNav} /> : <LoginForm setRedirect={setRedirect} />}
    </>

  )
}

export default Login;