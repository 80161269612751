import React from 'react'
// import Swiper bundle with all modules installed
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import ImagenPanel from '../../assets/Panel_interior.JPG'
import ImagenPanelExterior from '../../assets/PanelExterior.JPG'
import ImagenPersiana from '../../assets/Persianas.jpeg'



// import required modules
import { Autoplay, Navigation } from "swiper";


import { Link } from 'react-router-dom';

const Carrousel = () => {

  const carrouselData = [
    {
      title: "Nuestra Coleccion de laminados",
      image: "https://parador.de/media/wysiwyg/pbla593f2.jpg",
      text: "Sumérgete en un mundo de elegancia y estilo con los pisos laminados de Parador Colombia. Nuestra amplia selección de diseños y acabados te permitirá transformar tus espacios interiores en algo extraordinario. Estos pisos laminados no solo lucen increíbles, sino que también son altamente duraderos y fáciles de mantener. ",
      link: "/laminados"
    },
    {
      title: "Pisos Vinilo SPC - durables y versatiles",
      image: "https://parador.de/media/Home/vinyl-spc-home-web.jpg",
      text: "Explora la versatilidad y resistencia excepcional de los pisos de vinilo SPC de Parador Colombia. Diseñados para ofrecer una solución duradera y estilizada para tus espacios interiores y exteriores, nuestros pisos de vinilo SPC son la elección perfecta para aquellos que buscan una combinación de belleza y funcionalidad.",
      link: "/spc"


    },
    {
      title: "Pisos Estructurados ",
      image: "https://parador.de/media/Home/parkett_home_web.jpg",
      text: "Descubre la sofisticación y encanto natural de los pisos estructurados de Parador Colombia. Nuestra colección de pisos estructurados combina la belleza atemporal de la madera con la durabilidad y estabilidad de la ingeniería moderna. Cada tabla de nuestro piso estructurado es cuidadosamente diseñada para resaltar la autenticidad y textura de la madera, brindando un aspecto cálido y acogedor a cualquier espacio interior. ",
      link: "/estructurados"


    },
    {
      title: "Pisos Tipo Alfombra ",
      image: "https://d20f60vzbd93dl.cloudfront.net/uploads/tienda_008379/tienda_008379_c61383a753603b02f0af90ff2ef075543a5461b7_producto_large_90.png",
      text: "Descubre la comodidad y elegancia de los pisos tipo alfombra de Parador Colombia. Nuestros pisos tipo alfombra están diseñados para brindarte una experiencia de pisada suave y acogedora. Con una amplia variedad de estilos, colores y texturas, podrás encontrar la opción perfecta para complementar tu decoración. ",
      link: "/pisoAlfombra"


    },

    {
      title: "Persianas ",
      image: ImagenPersiana,
      text: "Descubre la elegancia funcional de nuestras persianas de Parador Colombia. Nuestras persianas ofrecen una combinación perfecta de estilo y practicidad para controlar la entrada de luz y brindar privacidad en tus espacios. ",
      link: "/persianas"


    },
    {
      title: "Paneles Interiores ",
      image: ImagenPanel,
      text: "Descubre la versatilidad y estilo contemporáneo de los paneles de PVC para interiores de Parador Colombia. Nuestra colección de paneles de PVC ofrece una solución moderna y práctica para transformar tus espacios de una manera elegante y funcional. ",
      link: "/panelesInterior"


    },

  ];
  return (
    <div className='h-screen'>
      <div className="swiper-container" style={{ height: '400px' }}>

        <Swiper modules={[Navigation, Autoplay]} autoplay className="h-auto">
          {carrouselData.map((item, index) => (
            <SwiperSlide key={index}>
              <div className='w-full h-full flex md:flex-row flex-col bg-black'>
                <div className='md:flex-1 md:h-full h-[350px]'>
                  <img src={item.image} className="h-full w-full object-center" alt={item.title}></img>
                </div>
                <div className='flex justify-start lg:mt-20 items-center flex-col md:flex-1 text-white'>
                  <div className="md:px-20 md:pt-20 pt-5 px-5 ">
                    <h1 className='text-3xl p-0 w-full lg:text-4xl xl:text-4xl'>{item.title}</h1>
                    <br />
                    <p className="text-sm font-light lg:text-lg xl:text-xl">{item.text}</p>
                    <br />
                    <Link to={item.link}>
                      <button className='bg-[#a17e67] px-10 py-3 rounded transition duration-500 hover:scale-110'>Ver Mas</button>
                    </Link>
                  </div>
                </div>

              </div>
            </SwiperSlide>

          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default Carrousel