import { signOut } from 'firebase/auth'
import React from 'react'
import { auth } from '../../firebase/firebaseConfig'
import NavbarDashBoard from './dashboard/NavbarDashBoard'
import { useState } from 'react'
import PisoLaminadoDash from './dashboard/pisoLaminadoDash'
import PisoEstructuradoDash from './dashboard/pisoEstructuradoDash'
import PisoSpcDash from './dashboard/pisoSpcDash'
import PersianasDash from './dashboard/persianasDash'
import PanelesExteriorDash from './dashboard/PanelesExteriorDash'
import PanelesInteriorDash from './dashboard/PanelesInteriorDash'
import PisoAlfombraDash from './dashboard/pisoAlfombraDash'
import HomePage from './dashboard/HomePage'

const DashBoard = ({setShowNav}) => {
    setShowNav(false);
    const [pisoLaminado, setPisoLaminado] = useState(false)
    const [pisoEstructurado, setPisoEstructurado] = useState(false)
    const [pisoAlfombra, setPisoAlfombra] = useState(false)
    const [panelesExterior, setPanelesExterior] = useState(false)
    const [panelesInterior, setPanelesInterior] = useState(false)
    const [pisoSpc, setPisoSpc] = useState(false)
    const [persianas, setPersianas] = useState(false)
    const [homePage, setHomePage] = useState(true)
    const userSignOut = () =>{
        signOut(auth).then(() =>{
            console.log("signed out successfully")
            window.location.reload();
        }).catch(error => console.log(error))
    }

  return (
    <>
    <div className='h-auto relative w-full bg-black flex flex-col items-center'>
       <NavbarDashBoard userSignOut={userSignOut} setpisoAlfombra={setPisoAlfombra} setPanelesExterior={setPanelesExterior} setPanelesInterior={setPanelesInterior} setPersianas={setPersianas} setPisoLaminado={setPisoLaminado} setPisoEstructurado={setPisoEstructurado} setPisoSpc={setPisoSpc} setHomePage={setHomePage}/>

       {homePage && 
       <HomePage />
       }
       
       {pisoLaminado && 
       
        <PisoLaminadoDash />
       }
       {pisoEstructurado && 
       <PisoEstructuradoDash />
       }
       {pisoSpc &&
       <PisoSpcDash />
       }
       {persianas && 
       <PersianasDash />
       }
       {panelesExterior &&
       <PanelesExteriorDash />

       }
       {panelesInterior &&
       <PanelesInteriorDash /> 
       }
       {pisoAlfombra && 
       <PisoAlfombraDash />
       }
    </div>
    </>
  )
}

export default DashBoard