import React from "react";
import GoogleMapReact from 'google-map-react';
import { MdLocationOn } from "react-icons/md";


const LocationPin = ({ text }) => (
    <div className="flex align-middle w-[180px] text-blue-500">
      <MdLocationOn className="text-4xl text-red-600"/>
      <p className="text-black text-xl">Cl. 94a #67A-74</p>
      
    </div>
  )

export default function SimpleMap(){
  const defaultProps = {
    center: {
      lat: 4.687754758945625,
      lng: -74.07341287862552
    },
    zoom: 17
  };
  const googleKey = process.env.REACT_APP_GOOGLE_API_KEY

  return (
    // Important! Always set the container height explicitly
    <div className="h-[350px] md:w-[60%] w-full ">
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleKey }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
     
      >
          <LocationPin
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
          
        />
      </GoogleMapReact>
    </div>
  );
}