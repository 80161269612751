import React from 'react'
import Map from './Map'
import { BsTelephoneFill } from 'react-icons/bs';
import { FaLocationArrow } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';



const MapSection = () => {
  return (
    <div className='h-auto w-full bg-black flex justify-center items-center'>
        <div className='w-full flex md:flex-row flex-col h-auto'>
         <div className='flex-1  h-full md:pl-10  '>
            <div className=' flex flex-col md:items-start  md:p-0 py-10'>  
                    <h1 className='text-white text-4xl font-normal '>ShowRoom Bogotá</h1>
                    <div className='mt-5 text-xl font-light md:py-3 '>
                    <p className='text-white flex items-center gap-2'><FaLocationArrow/> Calle 94A #67A-74, Local 25 </p>
                    <p className='text-white'> </p>
                    <a href="mailto:pisosydisenosfloresta@gmail.com" className='cursor-pointer text-white flex items-center gap-2'><MdEmail/>  pisosydisenosfloresta@gmail.com</a>
                    <a href='tel:+573174026747' className='cursor-pointer '><p className='text-white flex  items-center gap-2 mt-2'> <BsTelephoneFill/>  (317)-402-6747</p></a>
                    </div>
            </div>
         </div>
         <Map /> 
        </div>
        
    
    </div>
  )
}

export default MapSection