import React from 'react'
import PagesCarrousel from '../components/pages/PagesCarrousel'
import { useEffect } from 'react'


import { useState } from 'react';
import Cards from '../components/Cards';
import axios from 'axios';
import Loader from '../components/Loader';
const Estructurados = () => {
  const [blogs, setBlogs] = useState([])
  const isReady = blogs.length !== 0;
  const [imagenes, setImagenes] = useState([])

  useEffect(() => {
    const obtenerDatos = async () => {
      const data = {
        "product": "pisos_estructurados"
      }
      const datos = await axios.get(`https://pisos-parador-backend.onrender.com/api/getproducts`, {
        params: data
      })
      const imagenes = await axios.get(`https://pisos-parador-backend.onrender.com/api/getImages`, {
        params: data
      })

      setBlogs(datos.data);
      setImagenes(imagenes.data);




    }
    obtenerDatos();


  }, [setBlogs, setImagenes])
  return (

    <>
      {isReady ? (
        <div className='h-auto mt-16 bg-black text-white'>
          <PagesCarrousel />

          <h1 className='text-white text-5xl font-light text-center pt-5'>Estructurados</h1>


          <div className=' px-3 w-full  mt-4 flex justify-center   bottom-0'>
            <button className='bg-[#a17e67]  px-10 py-3 rounded transition hover:scale-110 text-white'>2.40 m</button>
            <button className='ml-3 bg-[#a17e67] px-10 py-3 rounded transition hover:scale-110 text-white'>1.20 m</button>
          </div>

          <Cards blogs={blogs} imagenes={imagenes} titulo="Piso estructurado" dataFrom="pisos_estructurados" />
        </div>

      ) : (

        <Loader />

      )}
    </>
  )
}

export default Estructurados