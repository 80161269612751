import React from 'react'
import { motion } from "framer-motion"

const Quote = () => {
  return (

    <div className='w-full h-96 bg-black flex justify-center md:p-5 lg:p-0 md:items-center'>

      <h1 className='text-[#a17e67] md:w-[75%] w-full md:leading-loose text-center font-light md:text-4xl text-3xl px-5 md:px-0'>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 1.5 } }}
        >
          Pisos y Diseños Floresta es la marca líder en el mercado de diseño de alta calidad para revestimientos de suelos y paredes.
        </motion.div>
      </h1>

    </div>

  )
}

export default Quote