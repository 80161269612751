import React from 'react'
import { motion } from 'framer-motion';
import {GrClose} from 'react-icons/gr'
import { doc, deleteDoc } from "firebase/firestore";
import { db } from '../../../firebase/firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getStorage, ref, listAll, deleteObject } from "firebase/storage";
import { storage } from '../../../firebase/firebaseConfig';


const Modal = ({setShowModal, blogtitle, blogid, nombredb}) => {



  const deleteItem = async(e)=>{
      e.preventDefault();
      const desertRef = ref(storage, `/${nombredb}/${blogtitle}`);
      

      try {
        await deleteDoc(doc(db, nombredb, blogid));
        listAll(desertRef)
        .then((res) => {
            res.items.forEach((itemRef) => {
            const desertRef2 = ref(storage,itemRef._location.path_);
            deleteObject(desertRef2)
      
        });
        }).catch((error) => {
        console.log("hay un error")
       
        });
        
        
        
        toast.success('se borro el producto con exito', {
            position: toast.POSITION.TOP_CENTER
        }); 
        setTimeout(() => {
            setShowModal(false);
            window.location.reload();
        }, 3000);
      } catch (error) {
        toast.error('Hubo un error al eliminar el producto', {
            position: toast.POSITION.TOP_CENTER
        });
          
      }
  }

  return (
    <motion.div
    className="px-3 lg:px-0  flex justify-center items-center overflow-x-hidden shadow-xl overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-modal-opacity "
    initial={{ y: "300px", opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
   
  >
        <div className="relative w-[300px] my-6 mx-auto max-w-3xl ">

              <div className="border-0 rounded-lg shadow-lg relative flex flex-col px-5 py-5 w-full bg-white outline-none focus:outline-none">
                  <div className="absolute w-4 h-4 cursor-pointer top-3 right-5  " onClick={() => setShowModal(false)}>
                      <GrClose />

                  </div>
                  <h1>Estas seguro que deseas eliminar <b>{blogtitle}?</b> </h1>
                  <div className='flex gap-3 mt-5'>
                      <button onClick={deleteItem} className='px-7 py-1 rounded transform duration-500 hover:scale-105 bg-green-400'>Si</button>
                      <button onClick={()=>{setShowModal(false)}} className='px-7 py-1 rounded transform duration-500 hover:scale-105 bg-red-400'>No</button>
                  </div>
                  

              </div>
        </div>
        <ToastContainer />

    </motion.div>
  )
}

export default Modal