import React from 'react'
import { useState } from 'react';
import Imagebg from '../../../assets/pisoLaminado.jpg'

import { collection, addDoc } from "firebase/firestore";
import { LoadingButton } from '@mui/lab';

import { db } from '../../../firebase/firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"




const HomePage = () => {
    const [selected, setSelected] = useState('pisos_laminados');
    const [titulo, setTitulo] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [medidas, setMedidas] = useState('');
    const [junta, setJunta] = useState('');
    const [grosor, setGrosor] = useState('');
    const [trafico, setTrafico] = useState('');
    const [precio, setPrecio] = useState(0);
    const [URLs, setURLs] = useState([]);
    const [progresspercent, setProgresspercent] = useState([]);
    const [files, setFiles] = useState([]);
    const [isLoading, SetIsloading] = useState(false);
    const boxSX = {
        backgroundColor: "#a17e67",
        padding: "12px 40px",
        transition: ".2s ease-in-out 0s",
        "&:hover": {
            cursor: "pointer",
            transform: "scale(1.05)",
            backgroundColor: "#a17e67",

        },
    };


    function handleLoading() {
        SetIsloading(!isLoading)

    }


    const handleSelectChange = (e) => {
        setSelected(e.target.value);
    }
    const handleFileChange = (e) => {
        setFiles(e.target.files);
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        SetIsloading(true);
        let data = {}
        const formData = new FormData();

        if (selected === "pisos_laminados" || selected === "pisos_estructurados" || selected === "pisos_spc" || selected === "pisos_tipo_alfombra") {
            // data = {
            //     "product": selected,
            //     "titulo": titulo,
            //     "precio": precio,
            //     "descripcion": descripcion,
            //     "trafico": trafico,
            //     "grosor": grosor,
            //     "junta": junta,
            //     "medidas": medidas,
            //     "files": files
            // }
            formData.append("product", selected);
            formData.append("titulo", titulo);
            formData.append("precio", precio);
            formData.append("descripcion", descripcion);
            formData.append("grosor", grosor);
            formData.append("junta", junta);
            formData.append("medidas", medidas);
            formData.append("trafico", trafico);
            for (const file of files) {
                formData.append("files", file);
            }


        }
        else {
            // data = {
            //     "product": selected,
            //     "titulo": titulo,
            //     "precio": precio,
            //     "descripcion": descripcion,
            //     "files": files
            // }
            formData.append("product", selected);
            formData.append("titulo", titulo);
            formData.append("precio", precio);
            formData.append("descripcion", descripcion);

            for (const file of files) {
                formData.append("files", file);
            }
        }
        await axios.post('http://localhost:5000/api/addProduct', formData)
            .then(response => {
                // Handle the response
                SetIsloading(false);
                console.log(response.data);
                toast.success('se subieron los archivos con exito', {
                    position: toast.POSITION.TOP_CENTER
                })


            })
            .catch(error => {
                // Handle the error
                toast.error('Hubo un error al agregar los productos', {
                    position: toast.POSITION.TOP_CENTER
                });
                console.error(error);
            });
    }


    const addProduct = async () => {
        try {
            if (selected === "pisos_laminados" || selected === "pisos_estructurados" || selected === "piso_spc" || selected === "piso_alfombra") {
                await addDoc(collection(db, selected), {
                    titulo: titulo,
                    descripcion: descripcion,
                    imagenes: URLs,
                    precio: precio,
                    medidas: medidas,
                    junta: junta,
                    grosor: grosor,
                    trafico: trafico
                })
            }
            else {
                await addDoc(collection(db, selected), {
                    titulo: titulo,
                    descripcion: descripcion,
                    imagenes: URLs,
                    precio: precio
                })
            }

            toast.success('se agregaron los productos con exito', {
                position: toast.POSITION.TOP_CENTER
            });
            setTitulo('');
            setDescripcion('');
            setURLs([]);
            setFiles([]);
            setPrecio('');
            setMedidas('');
            setGrosor('');
            setJunta('');
            setTrafico('');

        } catch (error) {

            toast.error('Hubo un error al agregar los productos', {
                position: toast.POSITION.TOP_CENTER
            });

        }

    }
    return (
        <div className='text-white h-auto p-10 bg-black w-full flex justify-center items-center bg-cover px-3 md:px-0 ' style={{ backgroundImage: `url(${Imagebg})` }}>

            <div className='h-auto md:w-[70%] w-full bg-white p-5 text-black rounded'>
                <h1 className='text-3xl font-normal'>Añadir productos</h1>
                <div className='flex flex-col mt-5'>
                    <label>Producto</label>
                    <select onChange={handleSelectChange} value={selected} className='md:w-[50%] w-full rounded text-black'>
                        <option value="pisos_laminados">piso Laminado</option>
                        <option value="pisos_estructurados">piso estructurado</option>
                        <option value="pisos_spc">piso spc</option>
                        <option value="pisos_tipo_alfombra">piso alfombra</option>
                        <option value="persianas">persianas</option>
                        <option value="paneles_interior">paneles interior</option>
                        <option value="paneles_exterior">paneles exterior</option>
                    </select>
                </div>
                <div className='flex md:flex-row flex-col gap-5 mt-5'>
                    <div className='flex flex-col flex-1'>
                        <label>Nombre</label>
                        <input type='text' name="nombre" className='rounded' placeholder='Nombre Del Producto' onChange={(e) => { setTitulo(e.target.value) }}></input>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <label>Precio</label>
                        <input type='text' name="precio" className='rounded' placeholder='Precio Por M2' onChange={(e) => { setPrecio(e.target.value) }}></input>
                    </div>

                </div>
                {(selected === "pisos_laminados" || selected === "pisos_estructurados" || selected === "pisos_spc" || selected === "pisos_tipo_alfombra") &&
                    <div className='grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-2 gap-5 mt-5'>
                        <div className='flex flex-col flex-1'>
                            <label>trafico</label>
                            <input type='text' name="trafico" className='rounded' placeholder='trafico' onChange={(e) => { setTrafico(e.target.value) }}></input>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <label>grosor</label>
                            <input type='text' name="grosor" className='rounded' placeholder='grosor Producto' onChange={(e) => { setGrosor(e.target.value) }}></input>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <label>junta</label>
                            <input type='text' name="junta" className='rounded' placeholder='junta del producto' onChange={(e) => { setJunta(e.target.value) }}></input>
                        </div>
                        <div className='flex flex-col flex-1'>
                            <label>Medidas</label>
                            <input type='text' name="medidas" className='rounded' placeholder='medidas del producto' onChange={(e) => { setMedidas(e.target.value) }}></input>
                        </div>

                    </div>

                }
                <div className='h-auto w-full flex flex-col mt-5'>
                    <label>Descripcion</label>
                    <textarea className='min-h-[80px] w-full rounded' placeholder='Descripcion Del Producto' onChange={(e) => { setDescripcion(e.target.value) }}></textarea>
                </div>

                <div className='flex flex-col mt-5 '>
                    <label>Seleccionar imagenes</label>
                    <input type="file" multiple className='rounded border-gray-300 border md:w-[40%] sm:w-1/2 w-full' onChange={handleFileChange} />
                    {progresspercent}
                </div>
                <div className='flex gap-5 py-3 mt-5'>
                    {/* className='bg-[#a17e67] text-white px-10 py-3 rounded transition hover:scale-110' */}

                    <LoadingButton sx={boxSX}
                        loading={isLoading}
                        variant="contained"
                        onClick={handleSubmit}>
                        Subir Archivos
                    </LoadingButton>

                </div>
            </div>
            <ToastContainer />

        </div>
    )
}

export default HomePage