import React from 'react'
import PagesCarrousel from '../components/pages/PagesCarrousel'
import { useEffect } from 'react'

import { useState } from 'react';
import Cards from '../components/Cards';
import axios from 'axios';
const PanelesExterior = () => {
  const [blogs, setBlogs] = useState([])
  const [imagenes, setImagenes] = useState([])
  const isReady = blogs.length !== 0;
  useEffect(() => {
    const obtenerDatos = async () => {
      const data = {
        "product": "paneles_exterior"
      }
      const datos = await axios.get(`https://pisos-parador-backend.onrender.com/api/getproducts`, {
        params: data
      })
      const imagenes = await axios.get(`https://pisos-parador-backend.onrender.com/api/getImages`, {
        params: data
      })

      setBlogs(datos.data);
      setImagenes(imagenes.data);




    }
    obtenerDatos();


  }, [])
  return (
    <>
      {isReady ? (
        <div className='h-auto mt-16 bg-black text-white'>
          <PagesCarrousel />
          <h1 className='text-white text-5xl font-light text-center pt-5'>Paneles Exterior</h1>

          <Cards blogs={blogs} imagenes={imagenes} titulo="paneles exterior" dataFrom="paneles_exterior" />

        </div>

      ) : (

        <div className='h-screen bg-black w-full flex justify-center items-center'>
          <div class="loader">
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
            <div class="loader-square"></div>
          </div>
        </div>

      )}
    </>

  )
}

export default PanelesExterior